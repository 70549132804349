import { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  BackgroundImagePosition,
  Body as BasicBody,
} from '@ac/kiosk-components';

import { getImages, getStyles } from 'store/settings/selectors';

import { GetComponentProps } from 'types/shared';

interface BodyProps extends GetComponentProps<typeof BasicBody> {
  hasBackgroundImage?: boolean;
}

export const Body = ({
  children,
  hasBackgroundImage = true,
  ...props
}: PropsWithChildren<BodyProps>): JSX.Element => {
  const styles = useSelector(getStyles);
  const images = useSelector(getImages);

  const backgroundImage = useMemo(() => {
    if (!hasBackgroundImage || !images?.BACKGROUND_IMAGE) return;

    return images?.BACKGROUND_IMAGE;
  }, [images?.BACKGROUND_IMAGE, hasBackgroundImage]);

  const imagePosition = useMemo(() => {
    if (!backgroundImage) return;

    return styles?.BACKGROUND_IMAGE_POSITION || BackgroundImagePosition.Tile;
  }, [styles?.BACKGROUND_IMAGE_POSITION, backgroundImage]);

  return (
    <BasicBody
      backgroundImage={backgroundImage}
      backgroundImagePosition={imagePosition}
      {...props}
    >
      {children}
    </BasicBody>
  );
};
